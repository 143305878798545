import { Avatar, IconButton, Tooltip } from "@mui/material";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { MACCOUNT_PROF } from "../../config/mstorage";
import { UserContext } from "../../context/userContext";
import { MdOutlineLogin } from "react-icons/md";
import { TiUserAddOutline } from "react-icons/ti";
import { AiOutlineAppstore } from "react-icons/ai";
import { MSoftware, MEducation, MAccount, M } from "../../config/links";

export default function Header() {
  const { user } = useContext(UserContext);

  return (
    <nav className={`bg-white md:pb-5 mx-0 pb-3 md:text-sm`}>
      <div className="md:mt-4 mt-3 gap-x-14 items-center max-w-screen-xl mx-auto px-4 flex md:px-8">
        <div className="flex items-center justify-between">
          <Link to="./">
            <h1 className="font-black text-3xl prime-color md:text-4xl">
              MPay
            </h1>
          </Link>
        </div>
        <div className={`flex-1 items-center md:mt-0 flex `}>
          {!user["mid"] && (
            <div className="text-right flex-1 gap-x-3 items-center justify-end space-y-6 md:flex md:space-y-0 md:mt-0">
              <Tooltip title="Login">
                <a
                  href={M + "./login"}
                  className="text-gray-700 hover:text-gray-900"
                >
                  <IconButton>
                    <MdOutlineLogin />
                  </IconButton>
                </a>
              </Tooltip>
              <Tooltip title="Create MAccount">
                <a href={M + "./start"}>
                  <IconButton>
                    <TiUserAddOutline />
                  </IconButton>
                </a>
              </Tooltip>
            </div>
          )}

          {user["mid"] && (
            <div className="text-right flex-1 gap-x-2 items-center justify-end md:flex">
              <MAccountProfile />
              <MComps />
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}

function MAccountProfile() {
  const { user } = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block">
      <IconButton
        id="dropdownUserAvatarButton"
        onMouseEnter={toggleDropdown} // Trigger dropdown on hover
        onMouseLeave={toggleDropdown} // Close dropdown when mouse leaves
      >
        <Avatar
          alt={user["maccount"]?.maccount}
          src={MACCOUNT_PROF + user["maccount"]?.profile}
        />
      </IconButton>
      {isOpen && (
        <div
          id="dropdownAvatar"
          className="z-10 absolute right-0 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 "
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="px-4 py-3 text-sm text-gray-900 ">
            <div>{user["maccount"]?.maccount}</div>
            <div className="font-medium truncate">
              {user["maccount"]?.fname + " " + user["maccount"]?.lname}
            </div>
          </div>
          <ul
            className="py-2 text-sm text-gray-700 "
            aria-labelledby="dropdownUserAvatarButton"
          >
            <li>
              <a href={MAccount} className="block px-4 py-2 hover:bg-gray-100 ">
                MAccount
              </a>
            </li>
            <li>
              <a
                href={M + "./start"}
                className="block px-4 py-2 hover:bg-gray-100 "
              >
                Create MAccount
              </a>
            </li>
          </ul>
          <div className="py-2">
            <a
              href={M + "./logout"}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 "
            >
              Sign out
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

function MComps() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block">
      <IconButton
        id="dropdownUserAvatarButton"
        onMouseEnter={toggleDropdown} // Trigger dropdown on hover
        onMouseLeave={toggleDropdown} // Close dropdown when mouse leaves
      >
        <AiOutlineAppstore />
      </IconButton>
      {isOpen && (
        <div
          id="dropdownAvatar"
          className="z-10 absolute right-0 mt-1 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 "
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <ul
            className="py-2 text-sm  "
            aria-labelledby="dropdownUserAvatarButton"
          >
            <li>
              <a
                href={MEducation}
                className="block text-md px-4 py-2 text-blue-500 hover:text-blue-500 hover:bg-gray-100 font-bold"
              >
                MEducation
              </a>
            </li>
            <li>
              <a
                href={MSoftware}
                style={{ color: "#0369a1" }}
                className="block text-md px-4 py-2 hover:bg-gray-100 font-bold"
              >
                MSoftware
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
