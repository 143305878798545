import Hero from "./components/hero";

function Home() {

  return (
    <>
      <Hero />
    </>
  );
};

export default Home;