import {
  BsFillEmojiAngryFill,
  BsFillEmojiFrownFill,
  BsFillEmojiHeartEyesFill,
  BsFillEmojiLaughingFill,
  BsFillEmojiNeutralFill,
  BsFillEmojiSmileFill,
} from "react-icons/bs";

export default function EmptyCard({ text, status }) {
  return (
    <>
      <div className="mt-3 text-center p-2">
        <div className="inline-block">
          {status === "Happy" && (
            <BsFillEmojiSmileFill color="gray" className="text-3xl" />
          )}
          {status === "Sad" && (
            <BsFillEmojiFrownFill color="gray" className="text-3xl" />
          )}
          {status === "Neutral" && (
            <BsFillEmojiNeutralFill color="gray" className="text-3xl" />
          )}
          {status === "Laughing" && (
            <BsFillEmojiLaughingFill color="gray" className="text-3xl" />
          )}
          {status === "Heart" && (
            <BsFillEmojiHeartEyesFill color="gray" className="text-3xl" />
          )}
          {status === "Angry" && (
            <BsFillEmojiAngryFill color="gray" className="text-3xl" />
          )}
        </div>
        <p className="mt-2">{text}</p>
      </div>
    </>
  );
}
