// external
import { Link } from "react-router-dom";
// // internal
import ManageContHead from "../../component/manage/manageconthead";
import { useContext } from "react";
import { MoneyContext } from "../../context/moneyContext";
import numberFormate from "../../utils/formateNumber";
import { IncomeContext } from "../../context/incomeContext";
import separateDate from "../../utils/separateDate";
import { Avatar, Divider } from "@mui/material";
import { ExpensesContext } from "../../context/expensesContext";
import { InvoicesContext } from "../../context/invoicesContext";
import { PMCardContext } from "../../context/pmCardContext";
import { PMDigitalContext } from "../../context/pmDigitalContext";
import EmptyCard from "../../component/emptyCard";

function MPHome() {
  const { moneyState } = useContext(MoneyContext);
  const { incomeState } = useContext(IncomeContext);
  const { expensesState } = useContext(ExpensesContext);
  const { pmCardState } = useContext(PMCardContext);
  const { pmDigitalState } = useContext(PMDigitalContext);
  const { invoicesState } = useContext(InvoicesContext);

  return (
    <div className="mg-cont-m">
      <ManageContHead title={"Home"} />

      <div className="masonry-grid masonry-grid-count-2 ">
        <div className="masonry-grid__item border">
          <div className="m-2 font-semibold text-gray-500">
            <h1>Money</h1>
          </div>
          <h1 className="text-center text-3xl font-semibold p-5">
            Rs.{numberFormate(moneyState ? moneyState : 0)}
          </h1>
        </div>

        <div className="masonry-grid__item border hover:bg-slate-50 !cursor-pointer">
          <Link to="../income">
            <div className="m-2 font-semibold text-gray-500">
              <h1>Income</h1>
            </div>
            <div className="">
              <div className="m-tb-wide">
                <div className="m-tb-wider-cont">
                  <div className="m-card-tb">
                    {Object.values(incomeState).map((key, index) => {
                      return (
                        <div className="m-card-tb-row" id={index}>
                          <div className="m-card-tb-col">
                            <div
                              className="flex"
                              style={{ alignItems: "center" }}
                            >
                              <div>
                                <Avatar
                                  alt={"Pay"}
                                  sx={{ width: 34, height: 34 }}
                                  src="MPay"
                                />
                              </div>
                              <div className="ml-2">
                                <p>MPay</p>
                              </div>
                            </div>
                          </div>
                          <div className="m-card-tb-col">
                            Rs.{numberFormate(key.amount)}
                          </div>
                          <div className="m-card-tb-col">
                            {separateDate(key.date)}
                          </div>
                        </div>
                      );
                    })}
                    {Object.values(incomeState).length === 0 && (
                      <>
                        <EmptyCard text="No Income" status="Sad" />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div className="masonry-grid__item border hover:bg-slate-50 !cursor-pointer">
          <Link to="../expense">
            <div className="m-2 font-semibold text-gray-500">
              <h1>Expense</h1>
            </div>
            <div className="">
              <div className="m-tb-wide">
                <div className="m-tb-wider-cont">
                  <div className="m-card-tb">
                    {Object.values(expensesState).map((key, index) => {
                      return (
                        <div className="m-card-tb-row" id={index}>
                          <div className="m-card-tb-col">{key.mc}</div>
                          <div className="m-card-tb-col">{key.mp}</div>
                          <div className="m-card-tb-col">
                            Rs.{numberFormate(key.amount)}
                          </div>
                          <div className="m-card-tb-col">
                            {separateDate(key.date)}
                          </div>
                        </div>
                      );
                    })}
                    {Object.values(expensesState).length === 0 && (
                      <>
                        <EmptyCard text="No Expense" status="Happy" />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div className="masonry-grid__item border hover:bg-slate-50 !cursor-pointer">
          <Link to="../invoice">
            <div className="m-2 font-semibold text-gray-500">
              <h1>Invoice</h1>
            </div>
            <div className="">
              <div className="m-tb-wide">
                <div className="m-tb-wider-cont">
                  <div className="m-card-tb">
                    {Object.values(invoicesState).map((key, index) => {
                      return (
                        <div className="m-card-tb-row" id={index}>
                          <div className="m-card-tb-col">
                            {key.invoice_number}
                          </div>
                          <div className="m-card-tb-col">
                            Rs.{numberFormate(key.total_amount)}
                          </div>
                          <div className="m-card-tb-col">
                            {key.payment_status == "unpaid" && (
                              <>
                                <span class="rounded-full bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-1 dark:bg-red-900 dark:text-red-300">
                                  {key.payment_status}
                                </span>
                              </>
                            )}
                            {key.payment_status == "paid" && (
                              <>
                                <span class="rounded-full bg-green-100 text-green-800 text-sm font-medium mr-2 px-2.5 py-1 dark:bg-green-900 dark:text-green-300">
                                  {key.payment_status}
                                </span>
                              </>
                            )}
                          </div>
                          <div className="m-card-tb-col">
                            {separateDate(key.date)}
                          </div>
                        </div>
                      );
                    })}
                    {Object.values(invoicesState).length === 0 && (
                      <>
                        <EmptyCard text="No Invoice" status="Neutral" />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div className="masonry-grid__item border">
          <div className="m-2 font-semibold text-gray-500">
            <h1>Payment Method</h1>
          </div>
          <div className="">
            <Divider textAlign="left" className="p-2">
              Card
            </Divider>
            <div className="m-tb-wide">
              <div className="m-tb-wider-cont">
                <div className="m-card-tb">
                  {Object.values(pmCardState)
                    .slice(0, 2)
                    .map((key, index) => {
                      return (
                        <div className="m-card-tb-row" id={index}>
                          <div className="m-card-tb-col">
                            {key.card_holder_name}
                          </div>
                          <div className="m-card-tb-col">
                            {hideCardNumber(key.card_number)}
                          </div>
                          <div className="m-card-tb-col">
                            {separateDate(key.date)}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>

            <Divider textAlign="left" className="p-2">
              Digital Pay
            </Divider>
            <div className="m-tb-wide">
              <div className="m-tb-wider-cont">
                <div className="m-card-tb">
                  {Object.values(pmDigitalState)
                    .slice(0, 2)
                    .map((key, index) => {
                      return (
                        <div className="m-card-tb-row" id={index}>
                          <div className="m-card-tb-col">{key.name}</div>
                          <div className="m-card-tb-col">{key.number}</div>
                          <div className="m-card-tb-col">
                            {separateDate(key.date)}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MPHome;

function hideCardNumber(cardNumber) {
  if (typeof cardNumber === "string" && cardNumber.length >= 6) {
    const firstThreeDigits = cardNumber.substring(0, 3);
    const lastThreeDigits = cardNumber.substring(cardNumber.length - 3);
    const hiddenDigits = "*".repeat(cardNumber.length - 6); // Replace the middle digits with asterisks

    return firstThreeDigits + hiddenDigits + lastThreeDigits;
  }

  // If the input is not a valid card number, return it as is
  return cardNumber;
}
