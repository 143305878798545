
// internal
import * as React from 'react';
import ManageContHead from '../../../component/manage/manageconthead';
import Card from './component/card';
import Digital from './component/digital';

export default function MPPM(){

  return (
    <div className="mg-cont-m">
      
      <ManageContHead title={"Payment Method"}/>

      <div className="container" style={{textAlign:'center'}}>
        
        <Card />    

        <Digital />    

      </div>

    </div>
  );
};
