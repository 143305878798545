import { createContext, useState } from "react";

const initialPMDigital = [];

export const PMDigitalContext = createContext(initialPMDigital);

export const PMDigitalProvider = ({ children }) => {
  const [pmDigitalState, setPMDigitalState] = useState(initialPMDigital);

  const setPMDigital = (arr) => {
    setPMDigitalState(arr);
  };

  const addPMDigital = (arr) => {
    setPMDigitalState((prevPMDigital) => [arr, ...prevPMDigital]);
  };
  
  const updatePMDigital = (PMDigitalId, updatedPMDigital) => {
    setPMDigitalState((prevPMDigital) =>
      prevPMDigital.map((PMDigital) =>
        PMDigital.id === PMDigitalId ? { ...PMDigital, ...updatedPMDigital } : PMDigital
      )
    );
  };

  const deletePMDigital = (PMDigitalId) => {
    setPMDigitalState((prevPMDigital) =>
      prevPMDigital.filter((PMDigital) => PMDigital.id !== PMDigitalId)
    );
  };

  return (
    <PMDigitalContext.Provider
      value={{
        pmDigitalState,

        setPMDigital,
        addPMDigital,
        updatePMDigital,
        deletePMDigital,
      }}
    >
      {children}
    </PMDigitalContext.Provider>
  );
};
