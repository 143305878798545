
// internal
import * as React from 'react';
import ManageContHead from '../../../component/manage/manageconthead';
import Money from './component/money';

export default function RenderData({money}){

  return (
    <div className="mg-cont-m">
      
      <ManageContHead title={"Money"}/>

      <div className="container" style={{textAlign:'center'}}>
        
        <Money money={money} />    

      </div>

    </div>
  );
};
