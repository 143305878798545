import { useState, useEffect, useContext } from "react";
// external
import { Outlet } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
// internal
import "./style/style.css";
import { setCookie } from "./hooks/useCookie";
import { idDecrypt, idEncrypt } from "./hooks/useEncryption";
import { UserContext } from "./context/userContext";
import {
  MF_INVOICE,
  MP_EXPENSE,
  MP_INCOME,
  MP_MONEY,
  MP_MPAY,
  MP_PMCARD,
  MP_PMDIGITAL,
  MSUBSCRIPTIONS,
  M_MA,
} from "./config/api";
import { getData } from "./hooks/useFetch";
import { IncomeContext } from "./context/incomeContext";
import { InvoicesContext } from "./context/invoicesContext";
import { ExpensesContext } from "./context/expensesContext";
import { MoneyContext } from "./context/moneyContext";
import { PMCardContext } from "./context/pmCardContext";
import { PMDigitalContext } from "./context/pmDigitalContext";
import { MPayContext } from "./context/mpayContext";

function App() {
  const [mid, setMId] = useState();
  const { startSession } = useContext(UserContext);
  const { setMPay } = useContext(MPayContext);
  const { setIncome } = useContext(IncomeContext);
  const { setExpenses } = useContext(ExpensesContext);
  const { setMoney } = useContext(MoneyContext);
  const { setPMCard } = useContext(PMCardContext);
  const { setPMDigital } = useContext(PMDigitalContext);
  const { setInvoices, setSubscriptions } = useContext(InvoicesContext);

  useEffect(() => {
    Promise.all([
      getData(M_MA, { column: "id", value: idDecrypt(getCookie("mid")) }),
      getData(MP_MPAY, { column: "mid", value: idDecrypt(getCookie("mid")) }),
      getData(MP_MONEY, { column: "mid", value: idDecrypt(getCookie("mid")) }),
      getData(MP_INCOME, { column: "mid", value: idDecrypt(getCookie("mid")) }),
      getData(MF_INVOICE, {
        column: "mid",
        value: idDecrypt(getCookie("mid")),
      }),
      getData(MSUBSCRIPTIONS, {
        column: "mid",
        value: idDecrypt(getCookie("mid")),
      }),
      getData(MP_EXPENSE, {
        column: "mid",
        value: idDecrypt(getCookie("mid")),
      }),
      getData(MP_PMCARD, { column: "mid", value: idDecrypt(getCookie("mid")) }),
      getData(MP_PMDIGITAL, {
        column: "mid",
        value: idDecrypt(getCookie("mid")),
      }),
    ])
      .then(
        ([
          maccount,
          mpay,
          money,
          income,
          invoices,
          subs,
          exp,
          pmCard,
          pmDigital,
        ]) => {
          startSession(idEncrypt(getCookie("mid")), maccount[0]);
          setMPay(mpay[0]);
          setMoney(money[0]?.money);
          setIncome(income.reverse());
          setInvoices(invoices.reverse());
          setSubscriptions(subs);
          setExpenses(exp.reverse());
          setPMCard(pmCard.reverse());
          setPMDigital(pmDigital.reverse());
        }
      )
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  }, [mid]);

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  useEffect(() => {
    setMId(idDecrypt(getCookie("mid")));
    // setCookie("mid", idEncrypt(1), 30);
  }, [mid]);

  return (
    <div className="app">
      <Outlet />
    </div>
  );
}

export default App;
