import { useContext } from "react";
import { ExpensesContext } from "../../../context/expensesContext";
import numberFormate from "../../../utils/formateNumber";
import separateDate from "../../../utils/separateDate";
import ManageContHead from "../../../component/manage/manageconthead";
import EmptyCard from "../../../component/emptyCard";

export default function MPExpense() {
  const { expensesState } = useContext(ExpensesContext);

  return (
    <>
      <ManageContHead title="Expense" />

      <div className="m-tb-wide">
        <div className="m-tb-wider-cont">
          <div className="m-card-tb">
            <div className="m-card-tb-row">
              <div className="m-card-tb-col">
                <b>M</b>
              </div>
              <div className="m-card-tb-col">
                <b>Product(s)</b>
              </div>
              <div className="m-card-tb-col">
                <b>Amount</b>
              </div>
              <div className="m-card-tb-col">
                <b>Invoice</b>
              </div>
              <div className="m-card-tb-col">
                <b>Date</b>
              </div>
            </div>
            {Object.values(expensesState).map((key, index) => {
              return (
                <div className="m-card-tb-row" id={index}>
                  <div className="m-card-tb-col">{key.mc}</div>
                  <div className="m-card-tb-col">{key.mp}</div>
                  <div className="m-card-tb-col">
                    Rs.{numberFormate(key.amount)}
                  </div>
                  <div className="m-card-tb-col">{key.invoice_number}</div>
                  <div className="m-card-tb-col">{separateDate(key.date)}</div>
                </div>
              );
            })}
            <div className="mt-5"></div>
            {Object.values(expensesState).length === 0 && (
              <>
                <EmptyCard text="No Expense" status="Happy" />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
