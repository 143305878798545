
import Form from './components/form';

function Register() {

  return (
    <>
      <Form />
    </>
  );
};

export default Register;