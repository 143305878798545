import { createContext, useState } from "react";

const initialMoney = 0;

export const MoneyContext = createContext(initialMoney);

export const MoneyProvider = ({ children }) => {
  const [moneyState, setMoneyState] = useState(initialMoney);

  const setMoney = (arr) => {
    setMoneyState(arr);
  };

  return (
    <MoneyContext.Provider
      value={{
        moneyState,

        setMoney,
      }}
    >
      {children}
    </MoneyContext.Provider>
  );
};
