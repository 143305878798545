// external
import { Fab } from "@mui/material";
import { Container, Row, Col } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import { MdAdd, MdClose } from "react-icons/md";
import { Jquery } from "react-jquery-plugin";
// internal
import Header from "./header";
import SideNav from "./sidenav";
import "./style/sidenav.css";
import "./style/manage/home.css";
import "./style/sidenav.css";
import "./style/style.css";
import "./style/root/index.css";

function MPManage() {
  Jquery(function () {
    Jquery("#openSidenav1").on("click", function () {
      Jquery("#sidenav").fadeIn("slow");
      Jquery("#openSidenav1").attr("style", "display: none !important");
      Jquery("#openSidenav2").fadeIn("slow");
    });

    Jquery("#openSidenav2").on("click", function () {
      Jquery("#sidenav").fadeOut("slow");
      Jquery("#openSidenav1").attr("style", "display: block !important");
      Jquery("#openSidenav2").fadeOut("slow");
    });
  });

  return (
    <>
      <Header />
      <>
        <Container className="mg-container" fluid>
          <Row className="justify-content-center">
            <Col xl={2} xxl={2} className="sidenav" id="sidenav">
              <SideNav />
            </Col>
            <Col sm={12} md={12} lg={12} xl={10} xxl={10} className="mg-cont">
              <Outlet />
            </Col>
          </Row>
        </Container>

        <Fab
          className="openSidebtn secondary-color !bg-gray-300 text-gray-600"
          style={{ display: "none" }}
          id="openSidenav2"
          size="small"
          aria-label="openSidebtn"
        >
          <MdClose style={{ display: "inline-block" }} />
        </Fab>

        <Fab
          className="openSidebtn secondary-color !bg-gray-300 text-gray-600"
          id="openSidenav1"
          size="small"
          aria-label="openSidebtn"
        >
          <MdAdd style={{ display: "inline-block" }} />
        </Fab>
      </>
    </>
  );
}

export default MPManage;
