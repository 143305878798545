// stateManager.js
import { useState } from 'react';

export function StateManager() {

  const [money, setMoney] = useState("");

  return { 

    money, setMoney,

  };

}