import { createContext, useState } from "react";

const initialPMCard = [];

export const PMCardContext = createContext(initialPMCard);

export const PMCardProvider = ({ children }) => {
  const [pmCardState, setPMCardState] = useState(initialPMCard);

  const setPMCard = (arr) => {
    setPMCardState(arr);
  };

  const addPMCard = (arr) => {
    setPMCardState((prevPMCard) => [arr, ...prevPMCard]);
  };
  
  const updatePMCard = (PMCardId, updatedPMCard) => {
    setPMCardState((prevPMCard) =>
      prevPMCard.map((PMCard) =>
        PMCard.id === PMCardId ? { ...PMCard, ...updatedPMCard } : PMCard
      )
    );
  };

  const deletePMCard = (PMCardId) => {
    setPMCardState((prevPMCard) =>
      prevPMCard.filter((PMCard) => PMCard.id !== PMCardId)
    );
  };

  return (
    <PMCardContext.Provider
      value={{
        pmCardState,

        setPMCard,
        addPMCard,
        updatePMCard,
        deletePMCard,
      }}
    >
      {children}
    </PMCardContext.Provider>
  );
};
