// MainComponent.js
import React from 'react';
import { StateManager } from './stateManager';
import { useDataFetching } from './effectHandler';
import RenderData from './renderData';

export default function MPMoney() {
  const { 
    
    money, setMoney,


  } = StateManager();

  useDataFetching({
    money, setMoney,

  });

  return <RenderData
    money={money}
  />
}