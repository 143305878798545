import { createContext, useState } from "react";

const initialInvoices = [];

export const InvoicesContext = createContext(initialInvoices);

export const InvoicesProvider = ({ children }) => {
  const [invoicesState, setInvoicesState] = useState(initialInvoices);
  const [subscriptionsState, setSubscriptionsState] = useState(initialInvoices);

  const setInvoices = (arr) => {
    setInvoicesState(arr);
  };

  const setSubscriptions = (arr) => {
    setSubscriptionsState(arr);
  };

  const addInvoices = (arr) => {
    setInvoicesState((prevInvoices) => [arr, ...prevInvoices]);
  };
  
  const updateInvoices = (invoiceId, obj) => {
    // Find the index of the object with the matching invoiceId
    const index = obj.findIndex(item => item.id === invoiceId);

    // Check if the index is valid (i.e., matching invoiceId found)
    if (index !== -1) {
      // Update the payment_status property to "paid"
      obj[index].payment_status = "paid";
    }

    // Return the updated array
    // return obj;
    setInvoicesState(obj);

    // setInvoicesState((prevInvoices) =>
    //   prevInvoices.map((Invoices) =>
    //     Invoices.id === InvoicesId ? { ...Invoices, ...updatedInvoices } : Invoices
    //   )
    // );
  };

  const deleteInvoices = (InvoicesId) => {
    setInvoicesState((prevInvoices) =>
      prevInvoices.filter((Invoices) => Invoices.id !== InvoicesId)
    );
  };

  return (
    <InvoicesContext.Provider
      value={{
        invoicesState,
        subscriptionsState,

        setInvoices,
        setSubscriptions,
        addInvoices,
        updateInvoices,
        deleteInvoices,
      }}
    >
      {children}
    </InvoicesContext.Provider>
  );
};
