
import { Alert, Box, Button, Chip, Divider, Snackbar, TextField } from "@mui/material";
import { useState } from "react";
import { MP_PMCARD } from "../../../../config/api";
import PageProgress from "../../../../component/progress";
import { useContext  } from 'react';
import { PMCardContext } from "../../../../context/pmCardContext";
import { UserContext } from "../../../../context/userContext";

export default function Card ({data}){
  
  const {user}  = useContext(UserContext);
  const {pmCardState, addPMCard}  = useContext(PMCardContext);
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(false);
  const [modelUl, setModelUl] = useState(false)

  // add

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  
  const [values, setValues] = useState({
    card_number: '',
    card_holder_name: '',
  });

  // form data sending
  const handleSubmit = async (e) => {
    e.preventDefault();
    setProgress(true)
    
    const form = e.target;
    const formDataObject = {};
    const formElements = form.elements;
    for (let i = 0; i < formElements.length; i++) {
      const input = formElements[i];
      if (input.name) {
        formDataObject[input.name] = input.value;
      }
    }
    formDataObject['mid'] = user.mid;
    formDataObject['card_number'] = values.card_number;
    formDataObject['card_holder_name'] = values.card_holder_name;

    fetch(MP_PMCARD, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formDataObject),
    })
    .then((response) => {
      addPMCard(formDataObject)
      if (response.ok) {
        setTimeout(() => {
          setProgress(false)
          setOpen(true)
        }, 1000); 
        setTimeout(() => {
          setOpen(false)
          setModelUl(false)
        }, 2000);
      } else {
        throw new Error("Error: " + response.status);
      }
    })
    .catch((error) => {
      console.error(error);
    });

  };

  const alumniProfile = Object.values(pmCardState).map((key, index) => (
    <div className="masonry-grid__item border rounded-lg " key={index}>
      <div className="py-2">
        <p>{key.card_holder_name}</p>
        <p className="text-xl font-semibold my-2">{hideCardNumber(key.card_number)}</p>
      </div>
    </div>
  )); 

  const handleShow = (id) => {
    setModelUl(true)
  }

  return (
    <>

      <PageProgress status={progress}/>

      <Box>
        <Divider textAlign="left" className="m-3"><Chip label="Card"/></Divider>
      </Box>

      <div className="masonry-grid masonry-grid-count-5">
        {alumniProfile}
        <div onClick={() => handleShow()} className="pt-2 masonry-grid__item text-gray-500 !border-gray-200 !border-dashed rounded-lg hover:bg-slate-50 !cursor-pointer" >
          <div className="py-4 ">
            <p>Add Card</p>
          </div>
        </div>
      </div>
      
    {modelUl ? (
      <div className="fixed inset-0 z-[100] overflow-y-auto">
        <div className="fixed inset-0 w-full h-full" style={{ background: "rgba(255, 255, 255, 0.9)", backdropFilter: "saturate(180%) blur(5px)" }} onClick={() => setModelUl(false)}></div>
        <div className="flex items-baseline min-h-screen !px-1 !py-1 md:!px-4 md:!py-8">
          <div className="relative w-full max-w-2xl	mx-auto bg-white rounded-md shadow-lg">
            <div className="flex items-center justify-between p-2 sm:!p-4 border-b">
              <h4 className="text-xl font-semibold text-gray-800">
                Add Card
              </h4>
              <button className="p-2 text-gray-400 rounded-md hover:bg-gray-100"
                onClick={() => setModelUl(false)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
            </div>

          <form  action={MP_PMCARD} method="POST" onSubmit={(event) => handleSubmit(event)}>
      
            <div className="space-y-2 p-1 md:!p-4 mt-4 mb-4 text-[15.5px] leading-relaxed text-gray-500">

              <section>
  
                  <div className='masonry-grid masonry-grid-count-1 '>

                    <div className='masonry-grid__item'>
                      <TextField required  id="card_holder_name" name="card_holder_name" value={values.card_holder_name} fullWidth onChange={handleChange('card_holder_name')} label="Card Holder name" variant="outlined" 
                      />
                    </div>
      
                    <div className='masonry-grid__item'>
                      <TextField
                        required
                        id="card_number"
                        name="card_number"
                        value={values.card_number}
                        fullWidth
                        onChange={handleChange('card_number')}
                        label="Card Number"
                        helperText="Please enter a 16-digit number"
                        variant="outlined"
                        inputProps={{
                          pattern: '^[0-9]{16}$', // 16 digits only
                          title: 'Please enter a 16-digit number',
                        }}
                      />
                    </div>

                  </div>


              </section>

            </div>
            
            <div className="text-right items-center border-t">
              <Box sx={{m:3}} style={{textAlign:"right"}}>
                <Button type="submit" variant="contained"  size="large" className='prime-btn !rounded-full'>Submit</Button>
              </Box>
            </div>

          </form>

          </div>
        </div>
      </div>
    ) : '' }

    <Snackbar open={open}  autoHideDuration={1000} 
      anchorOrigin={{vertical:"bottom",horizontal:"right"}}
    >
      <Alert  severity="success" variant='filled' sx={{ width: '100%' }}>
        Profile Saved Successfully
      </Alert>
    </Snackbar>
    
    </>
  )
}


function hideCardNumber(cardNumber) {
  if (typeof cardNumber === 'string' && cardNumber.length >= 6) {
    const firstThreeDigits = cardNumber.substring(0, 3);
    const lastThreeDigits = cardNumber.substring(cardNumber.length - 3);
    const hiddenDigits = '*'.repeat(cardNumber.length - 6); // Replace the middle digits with asterisks

    return firstThreeDigits + hiddenDigits + lastThreeDigits;
  }

  // If the input is not a valid card number, return it as is
  return cardNumber;
}