import { createContext, useState } from "react";

const initialIncome = [];

export const IncomeContext = createContext(initialIncome);

export const IncomeProvider = ({ children }) => {
  const [incomeState, setIncomeState] = useState(initialIncome);

  const setIncome = (arr) => {
    setIncomeState(arr);
  };

  const addIncome = (arr) => {
    setIncomeState((prevIncome) => [arr, ...prevIncome]);
  };
  
  const updateIncome = (IncomeId, updatedIncome) => {
    setIncomeState((prevIncome) =>
      prevIncome.map((Income) =>
        Income.id === IncomeId ? { ...Income, ...updatedIncome } : Income
      )
    );
  };

  const deleteIncome = (IncomeId) => {
    setIncomeState((prevIncome) =>
      prevIncome.filter((Income) => Income.id !== IncomeId)
    );
  };

  return (
    <IncomeContext.Provider
      value={{
        incomeState,

        setIncome,
        addIncome,
        updateIncome,
        deleteIncome,
      }}
    >
      {children}
    </IncomeContext.Provider>
  );
};
