import { Button, Chip, Divider } from "@mui/material";
import { useContext, useState } from "react";
import ManageContHead from "../../../component/manage/manageconthead";
import { Link } from "react-router-dom";
import { InvoicesContext } from "../../../context/invoicesContext";
import separateDate from "../../../utils/separateDate";
import numberFormate from "../../../utils/formateNumber";
import EmptyCard from "../../../component/emptyCard";

export default function MPInvoice() {
  const [invoiceShow, setInvoiceShow] = useState(false);
  const [modelShow, setModelShow] = useState(false);
  const { invoicesState, subscriptionsState } = useContext(InvoicesContext);

  const handleShow = (id) => {
    setModelShow(true);
    let selectSd = Object.values(invoicesState).filter((inv) => inv.id == id);
    setInvoiceShow(selectSd[0]);
  };
  console.log(invoicesState);
  return (
    <>
      <ManageContHead title={"Invoice"} />

      <div className="m-tb-wide">
        <div className="m-tb-wider-cont">
          <div className="m-card-tb">
            <div className="m-card-tb-row">
              <div className="m-card-tb-col">
                <b>Invoice</b>
              </div>
              <div className="m-card-tb-col">
                <b>Amount</b>
              </div>
              <div className="m-card-tb-col">
                <b>Subscriptions</b>
              </div>
              <div className="m-card-tb-col">
                <b>Status</b>
              </div>
              <div className="m-card-tb-col">
                <b>Date</b>
              </div>
            </div>
            <div className="mt-3"></div>
            {Object.values(invoicesState)
              .filter((s) => s.payment_status == "unpaid")
              .map((key, index) => {
                return (
                  <div
                    className="m-card-tb-row cursor-pointer"
                    id={index}
                    onClick={() => handleShow(key.id)}
                  >
                    <div className="m-card-tb-col">{key.invoice_number}</div>
                    <div className="m-card-tb-col">
                      Rs.{numberFormate(key.total_amount)}
                    </div>
                    <div className="m-card-tb-col">{key.sub_ids}</div>
                    <div className="m-card-tb-col">
                      {key.payment_status == "unpaid" && (
                        <>
                          <span class="rounded-full bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-1 dark:bg-red-900 dark:text-red-300">
                            {key.payment_status}
                          </span>
                        </>
                      )}
                      {key.payment_status == "paid" && (
                        <>
                          <span class="rounded-full bg-green-100 text-green-800 text-sm font-medium mr-2 px-2.5 py-1 dark:bg-green-900 dark:text-green-300">
                            {key.payment_status}
                          </span>
                        </>
                      )}
                    </div>
                    <div className="m-card-tb-col">
                      {separateDate(key.date)}
                    </div>
                  </div>
                );
              })}
            <div className="mt-3"></div>
            {Object.values(invoicesState)
              .filter((s) => s.payment_status == "paid")
              .map((key, index) => {
                return (
                  <div
                    className="m-card-tb-row cursor-pointer"
                    id={index}
                    onClick={() => handleShow(key.id)}
                  >
                    <div className="m-card-tb-col">{key.invoice_number}</div>
                    <div className="m-card-tb-col">
                      Rs.{numberFormate(key.total_amount)}
                    </div>
                    <div className="m-card-tb-col">{key.sub_ids}</div>
                    <div className="m-card-tb-col">
                      {key.payment_status == "unpaid" && (
                        <>
                          <span class="rounded-full bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-1 dark:bg-red-900 dark:text-red-300">
                            {key.payment_status}
                          </span>
                        </>
                      )}
                      {key.payment_status == "paid" && (
                        <>
                          <span class="rounded-full bg-green-100 text-green-800 text-sm font-medium mr-2 px-2.5 py-1 dark:bg-green-900 dark:text-green-300">
                            {key.payment_status}
                          </span>
                        </>
                      )}
                    </div>
                    <div className="m-card-tb-col">
                      {separateDate(key.date)}
                    </div>
                  </div>
                );
              })}
            <div className="mt-5"></div>
            {Object.values(invoicesState).length === 0 && (
              <>
                <EmptyCard text="No Invoice" status="Neutral" />
              </>
            )}
          </div>
        </div>
      </div>

      {modelShow ? (
        <div className="fixed inset-0 z-[100] overflow-y-auto">
          <div
            className="fixed inset-0 w-full h-full"
            style={{
              background: "rgba(255, 255, 255, 0.9)",
              backdropFilter: "saturate(180%) blur(5px)",
            }}
            onClick={() => setModelShow(false)}
          ></div>
          <div className="flex items-baseline min-h-screen px-2 py-2 md:!px-4 md:!py-8">
            <div className="relative w-full max-w-4xl	 mx-auto bg-white rounded-md shadow-md border border-gray-50">
              <div className="flex items-center justify-between px-3 py-2 border-b">
                <h4 className="text-xl font-medium text-gray-800">
                  {invoiceShow.invoice_number}
                </h4>
                <button
                  className="p-2 text-gray-400 rounded-md hover:bg-gray-100"
                  onClick={() => setModelShow(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 mx-auto"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>

              <div className="space-y-2 p-4 text-[15.5px] leading-relaxed text-gray-500">
                <div className="m-card-tb">
                  <div className="m-card-tb-row">
                    <div className="m-card-tb-col">
                      <span>Amount</span>
                    </div>
                    <div className="m-card-tb-col">
                      <span>Rs.{numberFormate(invoiceShow.total_amount)}</span>
                    </div>
                  </div>
                  <div className="m-card-tb-row">
                    <div className="m-card-tb-col">
                      <span>Status</span>
                    </div>
                    <div className="m-card-tb-col">
                      <span>{invoiceShow.payment_status}</span>
                    </div>
                  </div>
                  <div className="m-card-tb-row">
                    <div className="m-card-tb-col">
                      <span>Date</span>
                    </div>
                    <div className="m-card-tb-col">
                      <span>{separateDate(invoiceShow.date)}</span>
                    </div>
                  </div>
                </div>

                <Divider textAlign="left" sx={{ m: 2 }}>
                  <Chip label="Subscriptions" />
                </Divider>

                <div className="m-tb-wide">
                  <div className="m-tb-wider-cont">
                    <div className="m-card-tb">
                      <div className="m-card-tb-row">
                        <div className="m-card-tb-col">
                          <b>M</b>
                        </div>
                        <div className="m-card-tb-col">
                          <b>Products</b>
                        </div>
                        <div className="m-card-tb-col">
                          <b>Cost</b>
                        </div>
                        <div className="m-card-tb-col">
                          <b>Term</b>
                        </div>
                        <div className="m-card-tb-col">
                          <b>Status</b>
                        </div>
                        <div className="m-card-tb-col">
                          <b>Date</b>
                        </div>
                      </div>
                      {Object.values(subscriptionsState)
                        .filter((sub) => invoiceShow.sub_ids.includes(sub.id))
                        .map((key, index) => {
                          return (
                            <div className="m-card-tb-row" id={index}>
                              <div className="m-card-tb-col">{key.m}</div>
                              <div className="m-card-tb-col">{key.mp}</div>
                              <div className="m-card-tb-col">
                                Rs.{numberFormate(key.cost)}
                              </div>
                              <div className="m-card-tb-col">{key.term}</div>
                              <div className="m-card-tb-col">{key.status}</div>
                              <div className="m-card-tb-col">
                                {separateDate(key.date)}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>

              {invoiceShow.payment_status == "unpaid" && (
                <div className="text-right items-center p-4 border-t">
                  <Link to={"../checkout?i=" + invoiceShow.invoice_number}>
                    <Button
                      size="large"
                      variant="contained"
                      disableElevation={true}
                      className="prime-btn !rounded-md"
                    >
                      Pay
                    </Button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
