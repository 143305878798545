import { MBackend } from "./links";

export const M_MA            = MBackend + "m/maccount";
export const M_MR            = MBackend + "m/mrequests";
export const MSUBSCRIPTIONS = MBackend  + "m/msubscriptions";

export const MP_MPAY         = MBackend + "mpay";
export const MP_EXPENSE      = MBackend + "mpay/expense";
export const MP_INCOME       = MBackend + "mpay/income";
export const MP_INVOICE      = MBackend + "mpay/invoice";
export const MP_MONEY        = MBackend + "mpay/money";
export const MP_PMCARD       = MBackend + "mpay/pmcard";
export const MP_PMDIGITAL       = MBackend + "mpay/pmdigital";

export const ME_INST_IP      = MBackend + "meducation/inst/instproducts";

export const MF_SUBSCRIPTIONS = MBackend + "mfinance/subscriptions";
export const MF_INVOICE = MBackend + "mfinance/invoice";
