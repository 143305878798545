import { createContext, useState } from "react";
import { IncomeProvider } from "./incomeContext";
import { InvoicesProvider } from "./invoicesContext";
import { ExpensesProvider } from "./expensesContext";
import { MoneyProvider } from "./moneyContext";
import { PMCardProvider } from "./pmCardContext";
import { PMDigitalProvider } from "./pmDigitalContext";
import { MPayProvider } from "./mpayContext";

const muser = {
  mid: 0,
  maccount: '',
  money: 0,
}

export const UserContext = createContext(muser);

export const UserProvider = ({children}) => {

  const [user, setUser] = useState(muser);

  const startSession = (mid, maccount, money) => {
    setUser((user) => ({
      mid: mid,
      maccount: maccount,
      money: money,
    }));
  };

  return (
    <UserContext.Provider value={{user, startSession}}>
      <MoneyProvider>
        <IncomeProvider>
          <InvoicesProvider>
            <ExpensesProvider>
              <PMCardProvider>
                <PMDigitalProvider>
                  <MPayProvider>
                    {children}
                  </MPayProvider>
                </PMDigitalProvider>
              </PMCardProvider>
            </ExpensesProvider>
          </InvoicesProvider>
        </IncomeProvider>
      </MoneyProvider>
    </UserContext.Provider>
  )
}
