import { useContext } from "react";
import { IncomeContext } from "../../../context/incomeContext";
import { Avatar } from "@mui/material";
import ManageContHead from "../../../component/manage/manageconthead";
import numberFormate from "../../../utils/formateNumber";
import separateDate from "../../../utils/separateDate";
import EmptyCard from "../../../component/emptyCard";

export default function MPIncome() {
  const { incomeState } = useContext(IncomeContext);

  return (
    <div className="mg-cont-m">
      <ManageContHead title={"Income"} />

      <div className="container" style={{ textAlign: "center" }}>
        <div className="m-tb-wide">
          <div className="m-tb-wider-cont">
            <div className="m-card-tb">
              <div className="m-card-tb-row">
                <div className="m-card-tb-col">
                  <b>From</b>
                </div>
                <div className="m-card-tb-col">
                  <b>Amount</b>
                </div>
                <div className="m-card-tb-col">
                  <b>Payment Method</b>
                </div>
                <div className="m-card-tb-col">
                  <b>Date</b>
                </div>
              </div>
              {Object.values(incomeState).map((key, index) => {
                return (
                  <div className="m-card-tb-row" id={index}>
                    <div className="m-card-tb-col">
                      <div className="flex" style={{ alignItems: "center" }}>
                        <div>
                          <Avatar
                            alt={"Pay"}
                            sx={{ width: 34, height: 34 }}
                            src="MPay"
                          />
                        </div>
                        <div className="ml-2">
                          <p>MPay</p>
                        </div>
                      </div>
                    </div>
                    <div className="m-card-tb-col">
                      Rs.{numberFormate(key.amount)}
                    </div>
                    <div className="m-card-tb-col">System</div>
                    <div className="m-card-tb-col">
                      {separateDate(key.date)}
                    </div>
                  </div>
                );
              })}
              <div className="mt-5"></div>
              {Object.values(incomeState).length === 0 && (
                <>
                  <EmptyCard text="No Income" status="Sad" />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
