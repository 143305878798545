import React from 'react';
import ReactDOM from 'react-dom/client';
import { UserProvider } from './context/userContext'
// external
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// internal
import App from './App';
// css
import './style/index.css';
// root
  import Home from './pages/root/root/index';
import Register from './pages/root/register/register';
import Root from './pages/root';
import { CheckOut } from './pages/root/checkout/checkout';

import MPManage from './pages/manage/mp';
import MPHome from './pages/manage/home';
import MPIncome from './pages/manage/income';
import MPExpense from './pages/manage/expense';
import MPInvoice from './pages/manage/invoice';
import MPPM from './pages/manage/pm';
import MPMoney from './pages/manage/money';
const MPCheckout = React.lazy(() => import('./pages/manage/checkout/'));
const Admin = React.lazy(() => import('./pages/admin/'));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <UserProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} >

          <Route path="/" element={<Root />} >
            <Route path="" element={<Home />} /> 
            <Route path="start" element={<Register />} /> 
            <Route path="checkout" element={<CheckOut />} /> 
          </Route>

          <Route path="/manage" element={<MPManage />} >
            <Route path="home" element={<MPHome />} /> 
            <Route path="money" element={<MPMoney />} />
            <Route path="income" element={<MPIncome />} /> 
            <Route path="expense" element={<MPExpense />} />
            <Route path="invoice" element={<MPInvoice />} /> 
            <Route path="pm" element={<MPPM />} />  
            <Route path="checkout" element={<React.Suspense fallback={<></>}>
              <MPCheckout />
              </React.Suspense>
              } 
            /> 
          </Route>

          <Route path="admin" element={<React.Suspense fallback={<></>}>
            <Admin />
            </React.Suspense>
            } 
          /> 

          <Route path='*' element={
            <h1 style={{textAlign:"center"}}>404</h1>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  </UserProvider>
);
