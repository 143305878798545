// external
// mui components
import {
  MdClass,
  MdOutlineSupervisedUserCircle,
  MdOutlineSettings,
  MdOutlineMoney,
  MdOutlineGroups,
} from "react-icons/md";
import {
  BsBook,
  BsCreditCard2Front,
  BsImages,
  BsPersonPlus,
} from "react-icons/bs";
import { RiUser4Line, RiHome4Line } from "react-icons/ri";
import { TbDeviceMobile, TbReportMoney } from "react-icons/tb";
import { RiSoundModuleLine, RiMarkPenLine } from "react-icons/ri";
import { FaRegMoneyBillAlt, FaPenNib, FaMoneyBill } from "react-icons/fa";
import { FcSignature } from "react-icons/fc";
import { FiGitPullRequest } from "react-icons/fi";
import { BiCommentDetail, BiAccessibility, BiCart } from "react-icons/bi";
import { VscBook } from "react-icons/vsc";
import { GiTakeMyMoney, GiPayMoney, GiMoneyStack } from "react-icons/gi";

// internal

/*
 * @param {name} is the title of the icon
 * */

export default function Icons({ name, style }) {
  var icon = 0;
  switch (name) {
    case "MEducation":
      icon = (
        <span
          style={{ fontSize: "14px", fontWeight: "800" }}
          className="prime-color"
        >
          ME
        </span>
      );
      break;
    case "MPay":
      icon = (
        <span
          style={{ fontSize: "14px", fontWeight: "800" }}
          className="prime-color"
        >
          MP
        </span>
      );
      break;
    case "MJobs":
      icon = (
        <span
          style={{ fontSize: "14px", fontWeight: "800" }}
          className="prime-color"
        >
          MJ
        </span>
      );
      break;

    case "Home":
      icon = <RiHome4Line style={style} />;
      break;
    case "Message":
      icon = <BiCommentDetail style={style} />;
      break;
    case "Gallery":
      icon = <BsImages style={style} />;
      break;
    case "Facilities":
      icon = <BiAccessibility style={style} />;
      break;
    case "Classes":
      icon = <MdClass style={style} />;
      break;
    case "Programs":
      icon = <BsBook style={style} />;
      break;
    case "Courses":
      icon = <VscBook style={style} />;
      break;

    case "Income":
      icon = <GiTakeMyMoney style={style} />;
      break;
    case "Expenses":
      icon = <GiPayMoney style={style} />;
      break;

    case "Payroll":
      icon = <GiMoneyStack style={style} />;
      break;

    case "Salary":
      icon = <GiMoneyStack style={style} />;
      break;

    case "Fees":
      icon = <FaRegMoneyBillAlt style={style} />;
      break;

    case "Profile":
      icon = <RiUser4Line style={style} />;
      break;

    case "Attendance":
      icon = <RiMarkPenLine style={style} />;
      break;

    case "Registration":
      icon = <BsPersonPlus style={style} />;
      break;

    case "Admission":
      icon = <BsPersonPlus style={style} />;
      break;

    case "Online Admission":
      icon = <BsPersonPlus style={style} />;
      break;

    case "Application":
      icon = <BsPersonPlus style={style} />;
      break;

    case "Update":
      icon = <FaPenNib style={style} />;
      break;

    case "Modules":
      icon = <RiSoundModuleLine style={style} />;
      break;

    case "Finance":
      icon = <FaRegMoneyBillAlt style={style} />;
      break;

    case "Checkout":
      icon = <BiCart style={style} />;
      break;

    case "Student":
      icon = <MdOutlineGroups style={style} />;
      break;

    case "Parent":
      icon = <MdOutlineSupervisedUserCircle style={style} />;
      break;

    case "Setting":
      icon = <MdOutlineSettings style={style} />;
      break;

    case "Settings":
      icon = <MdOutlineSettings style={style} />;
      break;

    case "Requests":
      icon = <FiGitPullRequest style={style} />;
      break;

    case "Fee Structure":
      icon = <MdOutlineMoney style={style} />;
      break;

    case "Admission Online":
      icon = <TbDeviceMobile style={style} />;
      break;

    // roles

    case "Admin":
      icon = <MdOutlineSettings style={style} />;
      break;

    case "Admission Officer":
      icon = <FcSignature style={style} />;
      break;

    case "Accountant":
      icon = <TbReportMoney style={style} />;
      break;

    // ____

    // inst

    case "Money":
      icon = <GiMoneyStack style={style} />;
      break;

    case "Expense":
      icon = <FaMoneyBill style={style} />;
      break;

    case "Invoice":
      icon = <MdOutlineMoney style={style} />;
      break;

    case "Payment Method":
      icon = <BsCreditCard2Front style={style} />;
      break;

    // facitlities

    default:
      break;
  }

  return <>{icon}</>;
}
