// external
import * as React from "react";
import { Container } from "react-bootstrap";
// internal
import { Avatar } from "@mui/material";
import { useState } from "react";
import { UserContext } from "../../../../context/userContext";
import { MACCOUNT_PROF } from "../../../../config/mstorage";
import { MP_MONEY, MP_MPAY } from "../../../../config/api";
import { MPayContext } from "../../../../context/mpayContext";
import PageProgress from "../../../../component/progress";
import { useNavigate } from "react-router-dom";

export default function Form() {
  // auth
  const { user } = React.useContext(UserContext);
  const { setMPay } = React.useContext(MPayContext);
  const [result, setResult] = useState(false);
  const [progress, setProgress] = useState(false);
  const navigate = useNavigate();
  const [number, setNumber] = useState(user["maccount"]?.number);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProgress(true);

    const form = e.target;
    const formDataObject = {};
    const formElements = form.elements;

    for (let i = 0; i < formElements.length; i++) {
      const input = formElements[i];

      if (input.name) {
        if (input.type === "radio" && input.checked) {
          // If it's a radio button and it's checked, add its value
          formDataObject[input.name] = input.value;
        } else if (input.type !== "radio") {
          // For other input types, just add their values
          formDataObject[input.name] = input.value;
        }
      }
    }

    formDataObject["mid"] = user["mid"];
    formDataObject["number"] = number;
    fetch(MP_MPAY, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formDataObject),
    })
      .then((response) => {
        setMPay(formDataObject);
        if (response.ok) {
          setTimeout(() => {
            setProgress(false);
            setResult(true);
          }, 1000);
          setTimeout(() => {
            setResult(false);
            navigate("../");
          }, 7000);
        } else {
          throw new Error("Error: " + response.status);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    const formDataObjectMoney = {};
    formDataObjectMoney["mid"] = user["mid"];
    formDataObjectMoney["money"] = 0;

    // money account creation
    fetch(MP_MONEY, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formDataObjectMoney),
    })
      .then((response) => {})
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <PageProgress status={progress} />

      <Container className="mt-4">
        {result && (
          <>
            <h1 class="text-center mt-6 mb-3 text-2xl font-extrabold text-green-500 md:text-xl lg:text-4xl">
              <span class="text-gray-400 font-black"> MPay </span>
              Account Successfully Created.
            </h1>
            <p className="text-center py-2 text-xl">Navigating to Home...</p>
          </>
        )}
        {!result && (
          <>
            <h1 class="text-center mb-3 text-3xl font-extrabold text-gray-900 md:text-xl lg:text-4xl">
              Start with
              <span class="text-gray-400 font-black"> MPay </span>
            </h1>
            <form
              action={MP_MPAY}
              method="POST"
              onSubmit={(event) => handleSubmit(event)}
            >
              <div className="masonry-grid masonry-grid-count-3">
                <div className="masonry-grid__item m-border-none"></div>
                <div className="masonry-grid__item m-border-none">
                  <div className="text-center p-2">
                    <div className="inline-block">
                      <Avatar
                        alt={user["maccount"]?.maccount}
                        src={MACCOUNT_PROF + user["maccount"]?.profile}
                      />
                    </div>
                    <p>{user["maccount"]?.maccount}</p>
                  </div>

                  <div className="mt-4"></div>

                  <input
                    margin="normal"
                    required
                    fullWidth
                    placeholder="Number"
                    id="number"
                    name="number"
                    autoComplete="number"
                    onChange={(e) => setNumber(e.target.value)}
                    autoFocus
                    className={`w-full mt-2 px-3 py-3 text-gray-500 bg-transparent outline-none rounded-full 
                ${
                  result === true
                    ? "!border !border-green-500 focus:!border-green-600"
                    : "border !border-gray-400 focus:!border-gray-600 focus-within:shadow-sm"
                }`}
                  />

                  <div className="mt-4"></div>

                  <div className="flex p-2">
                    <div className="flex-1 p-1">
                      <div className="flex items-center text-left pl-4 border border-gray-200 rounded-full">
                        <input
                          id="bordered-radio-1"
                          type="radio"
                          value="pkr"
                          name="currency"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                        />
                        <label
                          htmlFor="bordered-radio-1"
                          className="w-full py-3 ml-2 text-sm font-medium text-gray-900"
                        >
                          Rs - PKR
                        </label>
                      </div>
                    </div>
                    <div className="flex-1 p-1">
                      <div className="flex items-center text-left pl-4 border border-gray-200 rounded-full">
                        <input
                          id="bordered-radio-2"
                          type="radio"
                          value="usd"
                          name="currency"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                        />
                        <label
                          htmlFor="bordered-radio-2"
                          className="w-full py-3 ml-2 text-sm font-medium text-gray-900"
                        >
                          $ - USD
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4"></div>
                  <div className="flex text-right items-center pt-2 !text-gray-600 ">
                    <div className="flex-1 text-left text-sm indent-1"></div>
                    <div className="flex-1 text-right">
                      <button
                        type="submit"
                        className={`px-3 py-2 text-center inline-flex items-center duration-150 bg-gray-50 rounded-full hover:bg-gray-100 active:bg-gray-200
                      ${
                        result === true
                          ? "!border !border-green-500 focus:!border-green-600"
                          : "border !border-gray-400 focus:!border-gray-600 focus-within:shadow-sm"
                      }`}
                      >
                        Create MPay
                      </button>
                    </div>
                  </div>
                </div>
                <div className="masonry-grid__item m-border-none"></div>
              </div>
            </form>
          </>
        )}
      </Container>
    </>
  );
}
