
import { Alert, Box, Button, Chip, Divider, Snackbar, TextField } from "@mui/material";
import { useState } from "react";
import { MP_PMDIGITAL } from "../../../../config/api";
import PageProgress from "../../../../component/progress";
import { useContext  } from 'react';
import { UserContext } from "../../../../context/userContext";
import { PMDigitalContext } from "../../../../context/pmDigitalContext";

export default function Digital ({data}){
  
  const {user}  = useContext(UserContext);
  const {pmDigitalState, addPMDigital}  = useContext(PMDigitalContext);
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(false);
  const [modelUl, setModelUl] = useState(false)

  // add

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  
  const [values, setValues] = useState({
    name: '',
    number: '',
  });

  // form data sending
  const handleSubmit = async (e) => {
    e.preventDefault();
    setProgress(true)
    
    const form = e.target;
    const formDataObject = {};
    const formElements = form.elements;

    for (let i = 0; i < formElements.length; i++) {
      const input = formElements[i];

      if (input.name) {
        if (input.type === 'radio' && input.checked) {
          // If it's a radio button and it's checked, add its value
          formDataObject[input.name] = input.value;
        } else if (input.type !== 'radio') {
          // For other input types, just add their values
          formDataObject[input.name] = input.value;
        }
      }
    }

    formDataObject['mid'] = user.mid;
    formDataObject['name'] = values.name;
    formDataObject['number'] = values.number;

    fetch(MP_PMDIGITAL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formDataObject),
    })
    .then((response) => {
      addPMDigital(formDataObject)
      if (response.ok) {
        setTimeout(() => {
          setProgress(false)
          setOpen(true)
        }, 1000); 
        setTimeout(() => {
          setOpen(false)
          setModelUl(false)
        }, 2000);
      } else {
        throw new Error("Error: " + response.status);
      }
    })
    .catch((error) => {
      console.error(error);
    });

  };

  const alumniProfile = Object.values(pmDigitalState).map((key, index) => (
    <div className="masonry-grid__item border rounded-lg " key={index}>
      <div className="py-2">
        <div className="mb-2 text-sm">
          {key.type}
        </div>
        <p>{key.name}</p>
        <p className="text-xl font-semibold my-2">{key.number}</p>
      </div>
    </div>
  )); 

  const handleShow = (id) => {
    setModelUl(true)
  }

  return (
    <>

      <PageProgress status={progress}/>

      <Box>
        <Divider textAlign="left" className="m-3"><Chip label="Digital Pay"/></Divider>
      </Box>

      <div className="masonry-grid masonry-grid-count-5">
        {alumniProfile}
        <div onClick={() => handleShow()} className="pt-2 masonry-grid__item text-gray-500 !border-gray-200 !border-dashed rounded-lg hover:bg-slate-50 !cursor-pointer" >
          <div className="py-4 ">
            <p>Add Digital Pay</p>
          </div>
        </div>
      </div>
        
      {modelUl ? (
        <div className="fixed inset-0 z-[100] overflow-y-auto">
          <div className="fixed inset-0 w-full h-full" style={{ background: "rgba(255, 255, 255, 0.9)", backdropFilter: "saturate(180%) blur(5px)" }} onClick={() => setModelUl(false)}></div>
          <div className="flex items-baseline min-h-screen !px-1 !py-1 md:!px-4 md:!py-8">
            <div className="relative w-full max-w-2xl	mx-auto bg-white rounded-md shadow-lg">
              <div className="flex items-center justify-between p-2 sm:!p-4 border-b">
                <h4 className="text-xl font-semibold text-gray-800">
                  Add Digital Payment
                </h4>
                <button className="p-2 text-gray-400 rounded-md hover:bg-gray-100"
                  onClick={() => setModelUl(false)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
              </div>

            <form  action={MP_PMDIGITAL} method="POST" onSubmit={(event) => handleSubmit(event)}>
        
              <div className="space-y-2 p-1 md:!p-4 mt-4 mb-4 text-[15.5px] leading-relaxed text-gray-500">

                <section>
    
                    <div className='masonry-grid masonry-grid-count-1 '>

                      <div className="masonry-grid__item">

                        <div className="flex p-2">

                          <div className="flex-1 p-1">
                            <div class="flex items-center text-left pl-4 border border-gray-200 rounded ">
                              <input id="bordered-radio-1" type="radio" value="easypaisa" name="type" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " />
                              <label for="bordered-radio-1" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 ">Easy Paisa</label>
                            </div>
                          </div>
                          <div className="flex-1 p-1">
                            <div class="flex items-center text-left  pl-4 border border-gray-200 rounded ">
                              <input id="bordered-radio-2" type="radio" value="jazzcash" name="type" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " />
                              <label for="bordered-radio-2" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 ">Jazz Cash</label>
                            </div>
                          </div>

                        </div>


                      </div>

                      <div className='masonry-grid__item'>
                        <TextField required  id="name" name="name" value={values.name} fullWidth onChange={handleChange('name')} label="Name" variant="outlined" 
                        />
                      </div>
        
                      <div className='masonry-grid__item'>
                        <TextField
                          required
                          id="number"
                          name="number"
                          value={values.number}
                          fullWidth
                          onChange={handleChange('number')}
                          label="Digital Number"
                          variant="outlined"
                        />
                      </div>

                    </div>

                </section>

              </div>
              
              <div className="text-right items-center border-t">
                <Box sx={{m:3}} style={{textAlign:"right"}}>
                  <Button type="submit" variant="contained"  size="large" className='prime-btn !rounded-full'>Submit</Button>
                </Box>
              </div>

            </form>

            </div>
          </div>
        </div>
      ) : '' }

      <Snackbar open={open}  autoHideDuration={1000} 
        anchorOrigin={{vertical:"bottom",horizontal:"right"}}
      >
        <Alert  severity="success" variant='filled' sx={{ width: '100%' }}>
          Profile Saved Successfully
        </Alert>
      </Snackbar>
      
    </>
  )
}