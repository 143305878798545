// external 
import { Outlet } from 'react-router-dom';
import Header from '../../component/header/header';

 function Root(){  

  return (
    <>
      <Header />
      <Outlet />
    </>
  );
  }

export default Root;
