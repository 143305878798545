import { createContext, useState } from "react";

const initialExpenses = [];

export const ExpensesContext = createContext(initialExpenses);

export const ExpensesProvider = ({ children }) => {
  const [expensesState, setExpensesState] = useState(initialExpenses);

  const setExpenses = (arr) => {
    setExpensesState(arr);
  };

  const addExpenses = (arr) => {
    setExpensesState((prevExpenses) => [arr, ...prevExpenses]);
  };
  
  const updateExpenses = (ExpensesId, updatedExpenses) => {
    setExpensesState((prevExpenses) =>
      prevExpenses.map((Expenses) =>
        Expenses.id === ExpensesId ? { ...Expenses, ...updatedExpenses } : Expenses
      )
    );
  };

  const deleteExpenses = (ExpensesId) => {
    setExpensesState((prevExpenses) =>
      prevExpenses.filter((Expenses) => Expenses.id !== ExpensesId)
    );
  };

  return (
    <ExpensesContext.Provider
      value={{
        expensesState,

        setExpenses,
        addExpenses,
        updateExpenses,
        deleteExpenses,
      }}
    >
      {children}
    </ExpensesContext.Provider>
  );
};
