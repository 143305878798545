import { createContext, useState } from "react";

const initialMPay = {
  mid: 0,
  number: 0,
  currency: '',
};

export const MPayContext = createContext(initialMPay);

export const MPayProvider = ({ children }) => {
  const [mpayState, setMPayState] = useState(initialMPay);

  const setMPay = (newMPay) => {
    setMPayState(newMPay);
  };
  
  const updateMPay = (updatedValues) => {
    // Merge the updated values with the current MPay object
    const updatedMPay = {
      ...mpayState,
      ...updatedValues,
    };
    setMPayState(updatedMPay);
  };

  return (
    <MPayContext.Provider
      value={{
        mpayState,
        setMPay,
        updateMPay,
      }}
    >
      {children}
    </MPayContext.Provider>
  );
};
