// ````
// imports
// external
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Avatar, CssBaseline } from "@mui/material";

// internal
import * as React from "react";
import "../../component/header/header.css";
import { UserContext } from "../../context/userContext";
import { Link } from "react-router-dom";

export default function Header() {
  const { user } = React.useContext(UserContext);

  const headToolbar = {
    minHeight: "50px",
  };

  return (
    <>
      <CssBaseline style={{ zIndex: "6" }}>
        <AppBar
          position="static"
          className="m-mg-head"
          style={{ boxShadow: "none" }}
        >
          <Container maxWidth="xl">
            <Toolbar style={headToolbar}>
              <Box
                sx={{ flexGrow: 1 }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                <Avatar
                  alt={user["maccount"].maccount}
                  sx={{ width: 38, height: 38 }}
                  src={
                    user["maccount"].profile
                      ? user["maccount"].profile
                      : user["maccount"].maccount
                  }
                />
                &nbsp; &nbsp;
                <Typography
                  variant="h6"
                  noWrap
                  style={{ display: "inline-block" }}
                  sx={{
                    m: 0,
                    fontFamily: '"Noto Sans", sans-serif',
                    fontWeight: 300,
                    fontSize: "18px",
                    color: "black",
                    textDecoration: "none",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  <span>{user.maccount.maccount}</span>
                </Typography>
              </Box>

              <Box sx={{ flexGrow: 1 }}></Box>

              <Box sx={{ flexGrow: 1 }} style={{ textAlign: "right" }}>
                <Link to="../">
                  <h1 className="prime-color font-black text-2xl">MPay</h1>
                </Link>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </CssBaseline>
    </>
  );
}
