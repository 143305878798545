import { Col, Row } from "react-bootstrap";

export default function Money ({money}){

  return (
    <>
      <Row className="m-4 p-3 md:m-4 md:p-5">
        <Col className="text-xl md:text-xl text-gray-300 font-semibold text-right">
          Total Money
        </Col>
        <Col className="text-2xl md:text-6xl  text-gray-400 font-bold text-left">
          {"Rs. " + money.money}
        </Col>
        <Col className="text-2xl md:text-6xl  text-gray-400 font-bold text-left">
        </Col>
      </Row>
    </>
  )
}