import { useContext } from "react";
import { Link } from "react-router-dom";
import { MPayContext } from "../../../../context/mpayContext";

export default function Hero() {
  const { mpayState } = useContext(MPayContext);

  const HeroHead = { head: "Payment System for Muqadar" };
  const HeroPara = {
    para: "You can start with mpay, deposit money, and invoice your subscriptions easily.",
  };
  console.log(mpayState);
  return (
    <div className="relative">
      <div className="relative">
        <section>
          <div
            style={{ alignItems: "center" }}
            className="max-w-screen-xl mx-auto !align-middle px-4 py-20 gap-12 text-gray-600 overflow-hidden md:px-8 md:flex"
          >
            <div className="flex-1 space-y-5 text-center">
              <h1 className="text-4xl text-gray-800 font-extrabold sm:text-5xl">
                {HeroHead.head}
              </h1>
              <p>{HeroPara.para}</p>
              <div className="inline-block">
                {!mpayState?.mid && (
                  <div className="flex items-center gap-x-3 sm:text-sm">
                    <Link
                      to="../start"
                      className="flex items-center justify-center gap-x-1 py-2 px-4 text-white font-medium bg-gray-500	 duration-150 hover:bg-gray-400	 active:bg-gray-600	 rounded-full md:inline-flex"
                    >
                      Start with MPay
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </Link>
                  </div>
                )}
                {mpayState?.mid && (
                  <div className="flex items-center gap-x-3 sm:text-sm">
                    <Link
                      to="../manage/home"
                      className="flex items-center justify-center gap-x-1 py-2 px-4 text-white font-medium bg-gray-500	 duration-150 hover:bg-gray-400	 active:bg-gray-600	 rounded-full md:inline-flex"
                    >
                      Go to MPay Manage
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
