// ````
// imports
// external
import { NavLink, useLocation } from "react-router-dom";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Card } from "@mui/material";

// internal
import * as React from "react";
import Icons from "../../component/icons";
// ____

export default function SideNav() {
  const location = useLocation();

  const navBar = [
    { name: "Home", link: "./home" },
    { name: "Income", link: "./income" },
    { name: "Expense", link: "./expense" },
    { name: "Invoice", link: "./invoice" },
    { name: "Payment Method", link: "./pm" },
  ];

  // Check if the user is on the checkout page and add the "Checkout" row if needed
  if (location.pathname === "/manage/checkout") {
    navBar.push({ name: "Checkout", link: "./checkout" });
  }

  const navBarHTML = navBar.map((key) => (
    <>
      {key.link && (
        <>
          <NavLink
            style={({ isActive }) => {
              return { opacity: isActive ? "0.5" : "" };
            }}
            to={key.link}
          >
            <ListItemButton className="sidenav-md-btn">
              <ListItemIcon>
                <Icons
                  name={key.name}
                  style={{
                    transform: "scale(1.1)",
                    borderRadius: "50px",
                    background:
                      "linear-gradient(90deg, rgb(128 141 145) 0%, #040D12 100%)",
                    color: "white",
                    padding: "5px",
                    fontSize: "30px",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={key.name} />
            </ListItemButton>
          </NavLink>
        </>
      )}
    </>
  ));

  return (
    <Card
      className="sidenav-cont"
      style={{ border: "none" }}
      variant="outlined"
    >
      <List
        sx={{ width: "100%", bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            MPay
          </ListSubheader>
        }
      >
        {navBarHTML}
      </List>
    </Card>
  );
}
