// effectHandler.js
import { useEffect } from 'react';
import { getData } from '../../../hooks/useFetch';
import { MP_MONEY } from '../../../config/api';
import { idDecrypt } from '../../../hooks/useEncryption';
import { getCookie } from '../../../hooks/useCookie';

export function useDataFetching({ 
  money, setMoney,
  }) {
  
  useEffect(() => {

    getData(MP_MONEY, {column: 'mid', value: idDecrypt(getCookie("mid"))})
    .then(res => {
      if (res.length > 0) {
        const obj = res[0];
        setMoney(obj)
      }
    })

  }, [])

}
